<template>
  <b-card>

    <!-- form -->
    <validation-observer ref="modelsCreate">
      <b-form>
        <b-row>
          <b-col md="12">
            <b-overlay
              :show="loading"
              rounded
              opacity="0.5"
              spinner-small
              spinner-variant="primary"
            >
              <div class="section-block mb-4">
                <div class="section-block__title">
                  <feather-icon
                    icon="GlobeIcon"
                    width="24"
                    height="24"
                  />
                  <h2>LTE Stats</h2>
                </div>
                <div class="neighbouring-form-container">
                  <b-form-group
                    label="Current Operator:"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Current Operator:"
                      vid="operator"
                      rules="max:255"
                    >
                      <b-form-input
                        v-model="form.operator"
                        disabled
                        :state="errors.length > 0 ? false:null"
                        placeholder="Current Operator:"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="ENB ID:"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="ENB ID:"
                      vid="enbId"
                      rules="max:255"
                    >
                      <b-form-input
                        v-model="form.enbId"
                        disabled
                        :state="errors.length > 0 ? false:null"
                        placeholder="ENB ID:"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="PHY Cell ID:"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="PHY Cell ID:"
                      vid="physicalCellId"
                      rules="max:255"
                    >
                      <b-form-input
                        v-model="form.physicalCellId"
                        disabled
                        :state="errors.length > 0 ? false:null"
                        placeholder="PHY Cell ID:"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="neighbouring-form-container">
                  <b-form-group
                    label="Sector ID:"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Sector ID:"
                      vid="sectorId"
                      rules="max:255"
                    >
                      <b-form-input
                        v-model="form.sectorId"
                        disabled
                        :state="errors.length > 0 ? false:null"
                        placeholder="Sector ID:"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="PCI:"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Session Uptime:"
                      vid="sessionUptime"
                      rules="max:255"
                    >
                      <b-form-input
                        v-model="form.sessionUptime"
                        disabled
                        :state="errors.length > 0 ? false:null"
                        placeholder="PCI:"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="neighbouring-form-container">
                  <b-form-group
                    label="Primary Band:"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Primary Band:"
                      vid="primaryBand"
                      rules="max:255"
                    >
                      <b-form-input
                        v-model="form.primaryBand"
                        disabled
                        :state="errors.length > 0 ? false:null"
                        placeholder="Primary Band:"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    v-if="form.caBand"
                    label="CA Band:"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="CA Band:"
                      vid="caBand"
                      rules="max:255"
                    >
                      <b-form-input
                        v-model="form.caBand"
                        disabled
                        :state="errors.length > 0 ? false:null"
                        placeholder="CA Band"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="d-flex w-100 chart-container">
                  <b-card
                    no-body
                    class="p-0"
                  >
                    <Chart
                      id="RSSI"
                      :traffic="RSIITraffic"
                      title="RSSI"
                      unit="dBm"
                      :raw-traffic="rawTraffic.rssi"
                    />
                  </b-card>
                  <b-card
                    no-body
                    class="p-0"
                  >
                    <Chart
                      id="RSRP"
                      :traffic="RSRPTraffic"
                      title="RSRP"
                      unit="dBm"
                      :raw-traffic="rawTraffic.rsrp"
                    />
                  </b-card>
                </div>
                <div class="d-flex w-100 chart-container">
                  <b-card
                    no-body
                    class="p-0"
                  >
                    <Chart
                      id="SINR"
                      :traffic="SINRTraffic"
                      title="SINR"
                      unit="dB"
                      :raw-traffic="rawTraffic.sinr"
                    />
                  </b-card>
                  <b-card
                    no-body
                    class="p-0"
                  >
                    <Chart
                      id="RSRQ"
                      :traffic="RSRQTraffic"
                      title="RSRQ"
                      unit="dB"
                      :raw-traffic="rawTraffic.rsrq"
                    />
                  </b-card>
                </div>
              </div>
            </b-overlay>
          </b-col>
          <!-- submit button -->
          <b-col
            cols="12"
            class="text-right buttons-container__left"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              ok-variant="secondary"
              @click.prevent="goBack"
            >
              Back
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard, VBToggle, BFormInput, BFormGroup, BForm, BButton, BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import axios from '../../axios-resolver'
import Chart from './Chart.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BCard, Chart, BFormInput, BFormGroup, BForm, ValidationProvider, ValidationObserver, BButton, BOverlay,
  },
  data() {
    return {
      wifiItems: [],
      loading: true,
      visitedTabs: [],
      isActive: false,
      hardwareId: this.$route.params.hardwareId,
      RSIITraffic: [],
      RSRPTraffic: [],
      SINRTraffic: [],
      RSRQTraffic: [],

      rawTraffic: {
        sinr: '',
        rssi: '',
        rsrp: '',
        rsrq: '',
      },

      form: {
        ssid: '',
        operator: '',
        enbId: '',
        sectorId: '',
        sessionUptime: '',
        physicalCellId: '',
        primaryBand: '',
      },
    }
  },
  beforeDestroy() {
    this.isActive = false
  },
  created() {
    // interval to add random traffic
    // hardwareId
    this.isActive = true
    setInterval(() => {
      if (this.isActive) {
        this.fetchGraphs()
      }
    }, 3_000)
    this.fetchTextFields()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    async fetchTextFields() {
      try {
        const { data } = await axios.get(`/hardware/control/${this.hardwareId}/lte/statistic`)
        this.form = data.data
      } catch (error) {
        console.warn(error)
      } finally {
        this.loading = false
      }
    },
    async fetchGraphs() {
      this.isActive = false
      try {
        const { data: { data } } = await axios.get(`/hardware/control/${this.hardwareId}/lte/statistic/signals`)
        this.RSIITraffic = [Math.abs(data.rssi)]
        this.RSRPTraffic = [Math.abs(data.rsrp)]
        this.SINRTraffic = [Math.abs(data.sinr)]
        this.RSRQTraffic = [Math.abs(data.rsrq)]
        this.rawTraffic = data
        this.isActive = true
      } catch (error) {
        this.isActive = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
.card{
    &-header{
        padding: 0 !important;
    }
}
</style>
