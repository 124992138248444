<template>
  <div>
    <p>{{ title }}:

      <span
        :style="{
          color: spanColor,
        }"
      >
        {{ rawTraffic }}
      </span>
      {{ unit }}
    </p>
    <canvas
      :id="id"
      width="400"
      height="80"
    />
  </div>
</template>

<script>
import Chart from 'chart.js/auto'

export default {
  name: 'Chart',
  props: {
    id: {
      required: true,
      type: String,
    },
    traffic: {
      required: true,
      type: Array,
    },
    title: {
      type: String,
      default: '',
    },
    unit: {
      type: String,
      default: '',
    },
    rawTraffic: {
      type: Number || String,
      default: 0,
    },
  },
  data() {
    return {
      chart: null,
    }
  },
  watch: {
    traffic() {
      this.updateChartData()
    },
  },
  mounted() {
    this.initChart()
  },
  methods: {
    getSignalStrength(label, value) {
      switch (label) {
        case 'SINR':
        // eslint-disable-next-line no-nested-ternary
          return value >= 20 ? 'strong' : value >= 13 ? 'medium' : 'low'
        case 'RSRQ':
        // eslint-disable-next-line no-nested-ternary
          return value >= -10 ? 'strong' : value >= -15 ? 'medium' : 'low'
        case 'RSSI':
        // eslint-disable-next-line no-nested-ternary
          return value >= -70 ? 'strong' : value >= -85 ? 'medium' : 'low'
        case 'RSRP':
          // eslint-disable-next-line no-nested-ternary
          return value >= -80 ? 'strong' : value >= -100 ? 'medium' : 'low'
        default:
          return 'low' // Default color if the label is unknown
      }
    },

    initChart() {
      const labels = []
      for (let count = 0; count <= 15; count += 1) {
        labels.push(count)
      }
      const config = {
        type: 'bar',
        data: {
          labels: [...labels],
          datasets: [
            {
              label: this.title,
              backgroundColor: '#228B22',
              borderColor: '#228B22',
              data: [],
              grouped: true,
              order: 2,
              xAxisID: 1,
              yAxisID: 'y1',
            },
            {
              label: this.title,
              backgroundColor: '#ffdb6b',
              borderColor: '#ffdb6b',
              data: [],
              grouped: true,
              order: 2,
              xAxisID: 1,
              yAxisID: 'y1',
            },
            {
              label: this.title,
              backgroundColor: 'orange',
              borderColor: 'orange',
              data: [],
              grouped: true,
              order: 2,
              xAxisID: 1,
              yAxisID: 'y1',
            },
          ],
        },
        options: {
          scales: {
            xAxes: [{
              type: 'realtime',
            }],
            y1: {
              // eslint-disable-next-line no-nested-ternary
              min: this.title === 'SINR' ? -20 : this.title === 'RSSI' ? 50 : 0,
              max: this.title === 'RSSI' ? 120 : null,
              display: true,
              ticks: {
                callback(value) {
                  if (this.title === 'SINR') {
                    return `${value}`
                  }
                  return `-${value}`
                },
              },
            },
          },
          responsive: true,
          plugins: {
            legend: {
              display: false,
              position: 'bottom',
            },
            title: {
              display: true,
              position: 'top',
              text: this.title,
              callbacks: {
                title() {
                  return ''
                },
                label(context) {
                  return `${context.dataset.label}- test` || ''
                },
              },
            },
            tooltip: {
              enabled: true,
              callbacks: {
                title() {
                  return ''
                },
                label(context) {
                  const label = context.dataset.label || ''
                  if (context.dataset.label === 'SINR') {
                    return label.concat(': ', new Intl.NumberFormat('en-US', { style: 'decimal' }).format(context.parsed.y), ' bits')
                  }
                  return label.concat(': -', new Intl.NumberFormat('en-US', { style: 'decimal' }).format(context.parsed.y), ' bits')
                  //     let label = context.dataset.label || ''

                  //     if (label) {
                  //       label += ': '
                  //     }
                  //     if (context.parsed.y !== null) {
                  //       label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y)
                  //     }
                  //     return label
                },
              },
            },
          },
        },
        plugins: [{
          afterLayout(chart) {
            chart.legend.legendItems.forEach(
              label => {
                const customLabel = `${label.text}2232`
                return customLabel
              },
            )
          },
        }],
      }
      this.chart = new Chart(
        window.document.getElementById(this.id),
        config,
      )
      this.updateChartData()
      // setInterval(() => {
      //  this.chart.data.datasets.forEach(dataset => {
      //    dataset.data.push(Math.random() * 30)
      //    if (dataset.data.length > 30) {
      //      dataset.data.shift()
      //    }
      //  })
      //  this.chart.update()
      // }, 2000)
    },
    updateChartData() {
      let isOverflow = false

      Object.values(this.traffic).forEach(traffic => {
        const datasetStrong = this.chart.data.datasets[0]
        const datasetMedium = this.chart.data.datasets[1]
        const datasetLow = this.chart.data.datasets[2]
        isOverflow = datasetStrong.data.length > 30 || datasetMedium.data.length > 30 || datasetLow.data.length > 30

        // ticks callback update

        this.chart.options.scales.y1.ticks.callback = value => {
          if (this.title === 'SINR') {
            return `${value}`
          }
          return `-${value}`
        }

        // tooltip label

        this.chart.options.plugins.tooltip.callbacks.label = context => {
          const label = context.dataset.label || ''
          if (context.dataset.label === 'SINR') {
            return label.concat(': ', new Intl.NumberFormat('en-US', { style: 'decimal' }).format(context.parsed.y), ` ${this.unit}`)
          }
          return label.concat(': -', new Intl.NumberFormat('en-US', { style: 'decimal' }).format(context.parsed.y), ` ${this.unit}`)
        }

        const signalStrength = this.getSignalStrength(this.title, traffic)

        switch (signalStrength) {
          case 'strong':
            datasetStrong.data.push(traffic)
            datasetMedium.data.push(null)
            datasetLow.data.push(null)
            this.spanColor = '#228B22'
            break
          case 'medium':
            datasetMedium.data.push(traffic)
            datasetStrong.data.push(null)
            datasetLow.data.push(null)
            this.spanColor = 'orange'
            break
          case 'low':
            datasetLow.data.push(traffic)
            datasetStrong.data.push(null)
            datasetMedium.data.push(null)
            this.spanColor = '#ffdb6b'
            break
          default:
            break
        }

        if (isOverflow) {
          datasetStrong.data.shift()
          datasetMedium.data.shift()
          datasetLow.data.shift()
        }
      })

      this.chart.update(isOverflow ? 'none' : null)
    },
  },
}
</script>

<style scoped>

</style>
