var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"modelsCreate"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"","opacity":"0.5","spinner-small":"","spinner-variant":"primary"}},[_c('div',{staticClass:"section-block mb-4"},[_c('div',{staticClass:"section-block__title"},[_c('feather-icon',{attrs:{"icon":"GlobeIcon","width":"24","height":"24"}}),_c('h2',[_vm._v("LTE Stats")])],1),_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Current Operator:","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Current Operator:","vid":"operator","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","state":errors.length > 0 ? false:null,"placeholder":"Current Operator:","autocomplete":"off"},model:{value:(_vm.form.operator),callback:function ($$v) {_vm.$set(_vm.form, "operator", $$v)},expression:"form.operator"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"ENB ID:","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"ENB ID:","vid":"enbId","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","state":errors.length > 0 ? false:null,"placeholder":"ENB ID:","autocomplete":"off"},model:{value:(_vm.form.enbId),callback:function ($$v) {_vm.$set(_vm.form, "enbId", $$v)},expression:"form.enbId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"PHY Cell ID:","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"PHY Cell ID:","vid":"physicalCellId","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","state":errors.length > 0 ? false:null,"placeholder":"PHY Cell ID:","autocomplete":"off"},model:{value:(_vm.form.physicalCellId),callback:function ($$v) {_vm.$set(_vm.form, "physicalCellId", $$v)},expression:"form.physicalCellId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Sector ID:","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Sector ID:","vid":"sectorId","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","state":errors.length > 0 ? false:null,"placeholder":"Sector ID:","autocomplete":"off"},model:{value:(_vm.form.sectorId),callback:function ($$v) {_vm.$set(_vm.form, "sectorId", $$v)},expression:"form.sectorId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"PCI:","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Session Uptime:","vid":"sessionUptime","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","state":errors.length > 0 ? false:null,"placeholder":"PCI:","autocomplete":"off"},model:{value:(_vm.form.sessionUptime),callback:function ($$v) {_vm.$set(_vm.form, "sessionUptime", $$v)},expression:"form.sessionUptime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Primary Band:","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Primary Band:","vid":"primaryBand","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","state":errors.length > 0 ? false:null,"placeholder":"Primary Band:","autocomplete":"off"},model:{value:(_vm.form.primaryBand),callback:function ($$v) {_vm.$set(_vm.form, "primaryBand", $$v)},expression:"form.primaryBand"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.form.caBand)?_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"CA Band:","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"CA Band:","vid":"caBand","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","state":errors.length > 0 ? false:null,"placeholder":"CA Band","autocomplete":"off"},model:{value:(_vm.form.caBand),callback:function ($$v) {_vm.$set(_vm.form, "caBand", $$v)},expression:"form.caBand"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,101784274)})],1):_vm._e()],1),_c('div',{staticClass:"d-flex w-100 chart-container"},[_c('b-card',{staticClass:"p-0",attrs:{"no-body":""}},[_c('Chart',{attrs:{"id":"RSSI","traffic":_vm.RSIITraffic,"title":"RSSI","unit":"dBm","raw-traffic":_vm.rawTraffic.rssi}})],1),_c('b-card',{staticClass:"p-0",attrs:{"no-body":""}},[_c('Chart',{attrs:{"id":"RSRP","traffic":_vm.RSRPTraffic,"title":"RSRP","unit":"dBm","raw-traffic":_vm.rawTraffic.rsrp}})],1)],1),_c('div',{staticClass:"d-flex w-100 chart-container"},[_c('b-card',{staticClass:"p-0",attrs:{"no-body":""}},[_c('Chart',{attrs:{"id":"SINR","traffic":_vm.SINRTraffic,"title":"SINR","unit":"dB","raw-traffic":_vm.rawTraffic.sinr}})],1),_c('b-card',{staticClass:"p-0",attrs:{"no-body":""}},[_c('Chart',{attrs:{"id":"RSRQ","traffic":_vm.RSRQTraffic,"title":"RSRQ","unit":"dB","raw-traffic":_vm.rawTraffic.rsrq}})],1)],1)])])],1),_c('b-col',{staticClass:"text-right buttons-container__left",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","ok-variant":"secondary"},on:{"click":function($event){$event.preventDefault();return _vm.goBack.apply(null, arguments)}}},[_vm._v(" Back ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }